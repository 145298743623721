@import "~@/erp/styles/variables/variables.scss";















































































































































































::v-deep {
  .el-table__body-wrapper {
    max-height: 400px;
    overflow-y: scroll;
  }
}
